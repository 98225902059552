.ContentLink {
    text-decoration: underline !important;
    text-decoration-style: dotted !important;
    text-decoration-thickness: 2px !important;
}

.ContentLink:hover {
    text-decoration-style: solid !important;
    cursor: pointer !important;
}

.ContentLink,
.ContentLink:hover,
.ContentLink:visited {
    color: #d1d1d1 !important;
    text-transform: none !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
}
