.CloseButton {
    position: absolute;
    z-index: 10010;
    right: 1.5em;
    top: .5em;
    background-color: transparent;
    border: transparent;
    padding: 0;
    margin: 0 auto 0 .3em;
    height: fit-content;
    width: fit-content;
}

.Modal .CloseButton {
    top: .6em;
    right: 1em;
}

.CloseButton:hover {
    transform: translateY(-1px);
    background-color: transparent;
}

.CloseButton i {
    color: grey;
    font-size: 2em;
}

button.CloseButton.CloseButton-clicked {
    transform: translate(1px, 1px) scale(0.95);
    transition: transform 0.1s ease-out;
}
