.TextBox {
    width: 90%;
    padding: 8px 15px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: box-shadow 0.3s;
    background-color: aliceblue;
}

.TextBox-placeholder {
    color: #444;
}

.TextBox-invalid{
    background-color: lightpink;
}
