.MailingListVerifyPage {
    flex-direction: column;
    padding: 1em;
    align-items: center;
    text-align: center;
}

.MailingListVerifyPage-logo {
    width: 192px;
    height: 192px;
    cursor: pointer;
}
