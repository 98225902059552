.NotFound {
    height: 100%;
    height: 100dvh;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.NotFound h1,
.NotFound p {
    margin: 4px;
}

.NotFound p {
    margin-top: 30px;
}
