.LoadableGame {
    margin: 0.3em 0.5em 0 0.5em;
    cursor: pointer;
}

.LoadableGame-tray {
    margin-top: -1em;
    justify-content: flex-end;
}

.LoadableGame-cols {
    /* flex-direction: column; */
    align-items: flex-start;
}

.LoadableGame-pic-col {
     margin: 1em 0 0 1em;
}
