.ChatPre {
  flex-grow: 1;
  margin: 1em;
  text-align: left;
}

.ChatPre-scene,
.ChatPre-loadable,
.ChatPre-new-game,
.ChatPre-insert-coin {
  color: lightgray;
}

.ChatPre-insert-coin {
  width: 100%;
  justify-content: center;
}

.ChatPre-insert-coin .ChatText {
  font-size: 1.5em;
}

.ChatPre-problem {
  color: darkorange;
}

.ChatPre-action,
.ChatPre-input,
.ChatPre-example {
  color: lightgreen;
  caret-color: lightgreen;
}

.ChatPre-input {
  margin-left: .6em;
}

.ChatPre-example {
  margin: 0em;
}

.ChatPre-cover {
  color: beige;
}

.ChatPre-suggestions {
  color: lightgray;
  display: flex;
  flex-direction: column;
}

.ChatPre,
.ChatPre textarea {
  font-family: 'Courier New', Consolas, Menlo, Monaco, monospace;
  font-size: 16px;
  font-weight: bold;
  white-space: pre-wrap;
}
