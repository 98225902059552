button.SubmitButton i.red {
    color: #e63838;
}

button.SubmitButton-submitting,
button.SubmitButton-submitted {
    cursor: default;
}

button.SubmitButton-submitted {
    background-color: darkgray;
}

button.SubmitButton-submitting:hover,
button.SubmitButton-submitted:hover {
    transform: translateY(0);
}

button.SubmitButton-submitting:active,
button.SubmitButton-submitted:active {
    transform: translateY(0);
}

button.SubmitButton-submitted:hover {
    background-color: darkgray;
}

button.SubmitButton-submitted:active {
    background-color: darkgray;
}

button.SubmitButton.SubmitButton-clicked {
    transform: translate(1px, 1px) scale(0.95);
    transition: transform 0.1s ease-out;
}
