.SaveCheckModal-button-pane {
    display: flex;
    flex-direction: column;
}

.SaveCheckModal-button-pane button {
    margin: 2em;
    padding: 1em;
}

.SaveCheckModal-header {
    margin-bottom: 20px;
}