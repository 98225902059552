.ChatBubble {
  max-width: 800px;
}

.ChatBubble-frame {
  background-color: #282c34;
  margin-top: .3em;
  margin-bottom: .3em;
  border: 1px solid grey;
  border-radius: 0.5em;
  box-shadow: inset 0 0 .5em rgba(0, 0, 0, 0.5), inset 0 0 .5em rgba(0, 0, 0, 0.3);
  width: 94vw;
  height: fit-content;
  position: relative;
  flex-direction: column;
  flex: 1;
}

.ChatBubble-frame.ChatBubble-frame-loadable {
  width: 90vw;
  margin-bottom: 0;
}

.ChatBubble-frame.ChatBubble-frame-new-game {
  width: fit-content;
  min-height: 380px;
  margin-bottom: 0;
}

.ChatBubble-frame-input {
  flex-direction: row;
  background-color: #222;
}