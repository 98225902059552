.RecommendedAction {
  padding-top: 0.1em;
  min-height: 19px;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.RecommendedAction:hover {
  text-decoration-style: solid;
  cursor: pointer;
}