.ContactPage {
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.ContactPage-footer {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 8em;
    margin-top: auto;
}
