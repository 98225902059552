.NewableGame {
    margin: 0.3em 0.5em 0 0.5em;
    cursor: pointer;
    width: fit-content;
    max-width: 280px;
}

.NewableGame.NewableGame-not-clickable {
    cursor: auto;
}

.NewableGame-rows {
    flex-direction: column;
    align-items: center;
}

.NewableGame-pic-row {
    margin: 1em 1em 0 1em;
    position: relative;
    z-index: 1;
}

.NewableGame-tray {
    position: absolute;
    right: 0;
    bottom: 0;
}

.NewableGame-overlay-box {
    position: absolute;
    z-index: 2;
    width: 256px;
    height: 256px;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;

}

.NewableGame-overlay-box .StatelessButton {
    align-self: flex-end;
    margin-bottom: 26px;
}
