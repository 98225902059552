.ReturnButton:hover {
    transform: translateY(-1px);
    background-color: transparent;
}

.ReturnButton,
.ReturnButton img {
    background-color: transparent;
    border: transparent;
    padding: 0;
    margin: 0;
    height: 40px;
    max-height: 7vw;
    margin-bottom: 6px;
    margin-right: 3px;
}

button.ReturnButton.ReturnButton-clicked {
    transform: translate(-2px, 2px) scale(0.95);
    transition: transform 0.1s ease-out;
}
