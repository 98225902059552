.MailingListSubscribe {
    width: 256px;
    height: 256px;
    background-image: url('/public/EndessGPT_Logo_256.webp');
    flex-direction: column;
    align-items: center;
}


.MailingListSubscribe>div {
    width: 100%;
    justify-content: center;
    flex: 1;
    align-items: center;
}

.MailingListSubscribe-messages {
    max-height: 50%;
}
