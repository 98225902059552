.LoadPage {
    flex-direction: column;
}

/* Pagination container */
.react-paginate {
    display: flex;
    align-self: center;
    list-style: none;
    padding: 0;
    background-color: #222;
    /* Dark background */
    border-radius: 5px;
    overflow: hidden;
}

/* Individual page items */
.react-paginate li {
    margin: 0;
    padding: 0;
    border-right: 1px solid #444;
    /* Slight border for separation */
}

.react-paginate li:last-child {
    border-right: none;
}

/* Page link */
.react-paginate li a {
    display: block;
    padding: 10px 13px;
    font-family: 'Courier New', Consolas, Menlo, Monaco, monospace;
    font-size: 16px;
    font-weight: bold;
    color: lightgreen;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

/* Hover and active styles */
.react-paginate li a:hover,
.react-paginate li a:active,
.react-paginate li a:focus {
    background-color: lightgreen;
    /* Neon green background */
    color: #222;
    /* Black text */
    outline: none;
    cursor: pointer;
}

/* Disabled and selected page */
.react-paginate li.disabled a,
.react-paginate li.selected a {
    background-color: #444;
    /* Darker background */
    color: lightgrey;
    /* White text */
    pointer-events: none;
    /* Disable click */
}