/* SwitchSelector.css */

.switch-selector-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: var(--wrapper-border-radius, 20px);
    border: var(--border, 0);
    background: var(--background-color, #c1c1c1);
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
    overflow: hidden;
}

.switch-selector-wrapper.disabled {
    opacity: 0.7;
}

.switch-selector-wrapper::before {
    left: 50%;
    top: var(--before-top, 0%);
    content: "";
    position: absolute;
    width: calc(100% - calc(var(--selection-indicator-margin, 2px) * 2));
    height: calc(calc(100% / var(--options-amount)) - calc(var(--selection-indicator-margin, 2px) * 2));
    border-radius: var(--option-border-radius, 18px);
    border: calc(var(--selection-indicator-margin, 2px)) solid var(--background-color, #c1c1c1);
    background: var(--selected-background-color, lightgreen);
    transition: top 0.1s linear, background 0.1s linear;
    transform: translateX(-50%);
    z-index: 1;
    box-sizing: content-box;
}

.switch-selector-option-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% / var(--options-amount));
    border-radius: var(--option-border-radius, 18px);
    box-sizing: border-box;
}

.switch-selector-option-item:not(:last-child) {
    border-bottom: var(--divider-border, 2px dashed #ccc);
}

.switch-selector-option-item-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: color 0.1s linear;
    cursor: pointer;
    font-size: var(--font-size, 14px);
    font-weight: 600;
    color: var(--font-color, #000);
}

.switch-selector-option-item-label.selected {
    color: var(--selected-font-color, #fff);
}

.switch-selector-option-item-label.disabled {
    cursor: not-allowed;
}

.switch-selector-option-input {
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
    position: absolute;
    pointer-events: none;
}
