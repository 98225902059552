.FlashingCursor {
    position: absolute;
    right: 0.9em;
    bottom: 18px;
}

.FlashingCursor-text {
    justify-content: right;
    top: 7px;
    width: 300px;
    height: 18px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    color: grey;
    font-family: 'Courier New', Consolas, Menlo, Monaco, monospace;
    font-size: 16px;
    font-weight: bold;
}

.FlashingCursor-text span {
    background-color: #292c33;
    padding: 0px 4px 0px 7px;
}

.FlashingCursor-square {
    width: 13px;
    height: 18px;
    background-color: lightgray;
    animation: blink 1s steps(1) infinite;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}