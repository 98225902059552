.ChatBubble-frame-insert-coin {
    align-items: center;
}

.ChatBubble-frame-insert-coin .StatelessButton {
    margin: 1em;
}

.ChatInsertCoin-img {
    max-width: 256px;
    max-height: 256px;
}
