.HelpSection {
    width: 94%;
    max-width: 800px;
    flex-direction: column;
    align-self: center;
    align-items: left;
}

.HelpSection h2,
.HelpSection h3 {
    align-self: center;
}

.HelpSection table {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    width: 96%;
    border-collapse: collapse;
    align-self: center;
}

.HelpSection table td,
.HelpSection table th {
    width: 50%;
    border-collapse: collapse;
    border: 1px solid grey;
    padding: 0.2em;
}

table.HelpSection-actions td,
p.HelpSection-actions,
ul.HelpSection-actions,
span.HelpSection-actions {
    font-family: 'Courier New', Consolas, Menlo, Monaco, monospace;
    font-size: 16px;
    font-weight: bold;
    color: lightgreen;
}

.HelpSection-chat-controls {
    list-style-type: none;
}

.HelpSection-chat-controls i {
    margin-right: 1em;
}

.HelpSection-chat-controls li {
    margin-bottom: 1em;
}

.HelpSection img {
    align-self: center;
    width: 400px;
    height: 400px;
    margin-top: 1em;
    margin-bottom: 1em;
}

img.HelpSection-small{
    width: 200px;
    height: 200px;
}