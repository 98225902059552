.App {
  background-color: #282c34;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  height: 100dvh;
}

.App-link {
  color: #61dafb;
}

.App-main-container {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 10000;
}

.Modal {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 100vw;
  height: 80vh;
  height: 80dvh;
  align-self: center;
  margin: 2em;
  border-radius: 1em;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScrollableModal-content {
  flex-direction: column;
  max-width: 800px;
  max-height: 75vh;
  max-height: 75dvh;
}

.ScrollableModal-inner {
  flex-direction: column;
  overflow-y: auto;
  margin-top: 1.5em;
}

.ScrollableModal-inner h2 {
  align-self: center;
}

.ScrollableModal-button-para {
  text-align: center;
}
