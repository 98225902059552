.OneOffPurchaseTable {
    margin-top: 2em;
    margin-bottom: 2em;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    background-color: #292c33;
    align-items: center;
}

@media (max-width: 999px) {

    .OneOffPurchaseTable {
        max-width: 400px;
    }
}

.OneOffPurchaseTable-inner {
    z-index: 10;
    flex-direction: column;
    width: 100%;
}

.OneOffPurchaseTable-under,
.OneOffPurchaseTable-over {
    position: absolute;
    width: .4em;
    height: .4em;
}

.OneOffPurchaseTable-under {
    z-index: 20;
    background-color: #292c33;
}

.OneOffPurchaseTable-over {
    z-index: 30;
    background-color: white;
}

.OneOffPurchaseTable-tl {
    top: 0;
    left: 0;
}

.OneOffPurchaseTable-over.OneOffPurchaseTable-tl {
    border-top-left-radius: 100%;
}

.OneOffPurchaseTable-tr {
    top: 0;
    right: 0;
}

.OneOffPurchaseTable-over.OneOffPurchaseTable-tr {
    border-top-right-radius: 100%;
}

.OneOffPurchaseTable-bl {
    bottom: 0;
    left: 0;
}

.OneOffPurchaseTable-over.OneOffPurchaseTable-bl {
    border-bottom-left-radius: 100%;
}

.OneOffPurchaseTable-br {
    bottom: 0;
    right: 0;
}

.OneOffPurchaseTable-over.OneOffPurchaseTable-br {
    border-bottom-right-radius: 100%;
}

.OneOffPurchaseTable-loading {
    margin-top: 3em;
    margin-bottom: 3em;
}
