.ContactForm {
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.ContactForm-switch {
    margin-top: 0.3em;
    width: 250px;
    height: 144px;
}

.ContactForm .TextInput {
    max-width: 700px;
}

.ContactForm .TextBox {
    height: 18em;
    max-width: 700px;
}

.ContactForm>label {
    margin-top: 1em;
}

.ContactForm .TextBox.TextBox-placeholder,
.ContactForm .TextInput.TextInput-placeholder {
    color: grey;
}

.ContactForm-messages {
    width: 50%;
    min-width: 200px;
    margin-bottom: 0.5em;
    align-items: center;
    justify-content: center;
}
