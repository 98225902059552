.ChatPanel {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: fit-content;
  flex: 1;
}

.ChatPanel-top-spacer {
  flex-direction: row;
  align-items: center;
  height: 80px;
  width: 100%;
}

.ChatPanel-sign-in {
  align-items: center;
  flex-direction: column;
  margin-left: auto;
}

.ChatPanel-sign-in span {
  margin-bottom: 4px;
}

.ChatPanel-group {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: fit-content;
}

.ChatPanel-cover-art {
  align-self: center;
  max-width: 800px;
  width: 94vw;
  border: 3px solid #7f654d;
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
}

.ChatPanel-cover-art img {
  width: 100%;
}

.ChatPanel-cover-art p {
  margin: 0;
  border: 0;
  padding: 0.5em;
}
