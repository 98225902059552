.HelpButton {
    background-color: transparent;
    border: transparent;
    padding: 0;
    margin: 0 0 0 .3em;
    height: fit-content;
    align-self: center;

    /* Styles to support pulse animation */
    position: relative;
    overflow: hidden;
}

.HelpButton-auto-margin {
    margin: 0 auto 0 0;
}

.HelpButton:hover {
    transform: translateY(-1px);
    background-color: transparent;
}

.HelpButton i {
    color: lightgray;
    font-size: 1.5em;
}

.ChatBubble-frame-input .HelpButton i {
    color: grey;
    font-size: 2em;
}

button.HelpButton.HelpButton-clicked {
    transform: translate(1px, 1px) scale(0.95);
    transition: transform 0.1s ease-out;
}

.HelpButton-pulse::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #acea9a;

    animation: HelpButton-radialPulse 1s infinite;
}

@keyframes HelpButton-radialPulse {
    0% {
        width: 0;
        height: 0;
        opacity: 1;
    }

    70% {
        width: 25px;
        height: 25px;
        opacity: 0.5;
    }

    100% {
        width: 42px;
        height: 42px;
        opacity: 0;
    }
}
