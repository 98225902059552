.AlertModal-button-pane {
    display: flex;
    flex-direction: column;
}

.AlertModal-button-pane button {
    margin: 2em;
    padding: 1em;
}

.AlertModal-header {
    margin-bottom: 20px;
    text-align: center;
}

.AlertModal-img-box {
    max-width: 256px;
    max-height: 256px;
}

.AlertModal-img-box img {
    width: 100%;
}
