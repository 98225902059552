.ChatInput-textarea {
  color: lightgreen;
  caret-color: lightgreen;
  flex-grow: 1;
  border: 0;
  margin: 0;
  padding: 0;
  resize: none;
  overflow: hidden;
  background-color: #222;
}

.ChatInput-textarea:focus {
  outline: 0;
}

.ChatInput-barricade {
  flex-grow: 1;
  height: 1em;
  background: repeating-linear-gradient(
        135deg,
        #c1c1c1,
        #c1c1c1 15px,
        #303030 15px,
        #303030 30px
    );
    -webkit-mask-image: linear-gradient(
      to right,
      transparent,
      black 10%,
      black 90%,
      transparent
    );
    mask-image: linear-gradient(
      to right,
      transparent,
      black 10%,
      black 90%,
      transparent
    );
}
