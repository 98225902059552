.JourneyLoadBubble {
  max-width: 800px;
}

.JourneyLoadBubble-frame {
  background-color: grey;
  margin-top: 1em;
  margin-bottom: 2em;
  padding-bottom: 0.7em;
  border: 1px solid #555;
  border-radius: 0.5em;
  box-shadow: inset 0 0 .5em rgba(0, 0, 0, 0.5), inset 0 0 .5em rgba(0, 0, 0, 0.3);
  width: 94vw;
  height: fit-content;
  position: relative;
  flex-direction: column;
  flex: 1;
  align-items: center;
}
