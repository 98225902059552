.FormMessages {
    width: 96%;
    height: 100%;
    background-color: #c0ffc0;
    border-radius: 0.5em;
    box-shadow: inset 0 0 0.3em #000000;
    font-size: 0.9rem;
    border: 1px solid #CCC;
    overflow-y: auto;
    padding: 0.4em;
    color: black;
}

.FormMessages.FormMessages-failure {
    padding: 0.1em;
    background-color: lightpink;
}

.FormMessages-list {
    list-style: none;
    padding: 0;
    margin: .5em .5em .5em 1.5em;
}

.FormMessages-item {
    margin-bottom: .1em;
    position: relative;
}

.FormMessages-item::before {
    content: "•";
    display: inline-block;
    position: absolute;
    left: -0.8em;
}
