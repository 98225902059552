.ConditionsModal .Modal-content {
    flex-direction: column;
    height: 100%;
}

.ConditionsModal .ScrollableModal-content {
    position: relative;
    flex: 1 1 auto;
    min-height: 0;
}

.ConditionsModal-button-pane {
    flex-shrink: 0;
    height: fit-content;
    margin-top: auto;
    flex-direction: column;
}

.ConditionsModal-button-pane button {
    margin: 2em;
    padding: 1em;
}

.ConditionsModal-header {
    margin-bottom: 20px;
    text-align: center;
    align-self: center;
}
