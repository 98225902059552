.Sidebar-endless-title {
  cursor: pointer;
}

.Sidebar-endless-logo {
  margin-right: .5em;
  margin-bottom: -.05em;
  width: 1.05em;
  height: 1.05em;
}

.Sidebar-chat-thumb {
  width: 1.2em;
  height: 1.2em;
  margin-left: .4em;
  margin-bottom: -.15em;
}

.bm-item-list .bm-cross {
  background: #888;
}

.bm-item-list h2 {
  margin: 0 auto;
  padding: .2em 0em .8em .2em;
  color: #d1d1d1;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.bm-item-list h2 span {
  font-size: 1.2em;
  font-weight: 700;
}

.bm-item-list a {
  padding: 1em 1em 1em .2em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.bm-item-list a span {
  letter-spacing: 1px;
  font-weight: 400;
  margin-left: .4em;
}

.bm-item-list a span.Sidebar-account-span {
  margin-left: .2em;
}

.bm-item-list a:hover,
.bm-item-list a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

.bm-item-list .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.bm-item-list .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 40px;
  height: 32px;
  left: 17px;
  top: 17px;
  border: 2px solid rgb(55, 58, 71);
  border-top: 1px;
  border-bottom: 1px;
  border-radius: 5px;
  /* box-shadow: inset 0 0 .5em rgba(0, 0, 0, 0.5), inset 0 0 .5em rgba(0, 0, 0, 0.3); */
  background: #222;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: gray;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid transparent;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: lightgreen;
  ;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  height: 100dvh;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: .2em .1em 0;
  font-size: 1.15em;
  flex-grow: 1;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  flex-grow: 1;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
  display: inline-block;

  color: #d1d1d1;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item.Sidebar-account {
  padding-top: .5em;
  padding-bottom: .5em;
}

.Sidebar-policy {
  margin-top: .5em;
  font-size: .75em;
}

.SideBar-login {
  cursor: pointer;
}

.Sidebar-balance {
  text-transform: none;
  font-size: smaller;
}

.Sidebar-tokens {
  color: white;
}
