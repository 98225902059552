button.StatelessButton:hover {
    transform: translateY(-1px);
}

button.StatelessButton:active,
button.StatelessButton:active {
    transform: translateY(0);
}

button.StatelessButton.StatelessButton-clicked {
    transform: translate(1px, 1px) scale(0.95);
    transition: transform 0.1s ease-out;
}
