.LoadButton:hover {
    transform: translateY(-1px);
    background-color: transparent;
}

.LoadButton {
    background-color: transparent;
    border: transparent;
    padding: 0;
    margin: 0;
}

.LoadButton i {
    color: lightgray;
    font-size: 1em;
}

.LoadButton i.green {
    color: lightgreen;
}

.LoadButton i.red {
    color: #df9076;
}

button.LoadButton.LoadButton-clicked {
    transform: translate(1px, 1px) scale(0.95);
    transition: transform 0.1s ease-out;
}
