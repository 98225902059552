.CaptchaModal-gif-preload {
    position: relative;
    height: 0px;
    width: 0px;
}

.CaptchaModal-gif-preload>img {
    position: absolute;
    height: 0px;
    width: 0px;
    left: -50000px;
}

.CaptchaModal-button-pane {
    display: flex;
    flex-direction: column;
}

.CaptchaModal-button-pane button {
    margin: 2em;
    padding: 1em;
}

.CaptchaModal-header {
    margin-bottom: 20px;
}
