.PurchaseHistory {
    margin-top: 2em;
    margin-bottom: 2em;
    flex-direction: column;
    font-size: 0.9em;
}

.PurchaseHistory-loading {
    margin-top: 3em;
    margin-bottom: 3em;
}

.PurchaseHistory table,
.PurchaseHistory td,
.PurchaseHistory th {
    color:#292c33;
    background-color: #c1c1c1;
    border-collapse: collapse;
    border: 1px solid #292c33;
}

.PurchaseHistory td,
.PurchaseHistory th {
    padding: .3em;
    text-align: left;
}

.PurchaseHistory th {
    hyphens: auto;
    background-color: #b1b1b1;
}
