.Page {
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    word-break: break-word;
}

.Page-top-spacer {
    width: 100%;
    height: 28px;
}

.Page h1 {
    text-align: center;
}

.Page-padded {
    padding-left: 3vw;
    padding-right: 3vw;
}

.Page-scrolls {
    overflow: auto;
    overflow-x: hidden;
}

.Page-loading {
    width: 100%;
    height: 100%;
    height: 100dvh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
