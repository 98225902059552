.LazyPicture {
  align-self: center;
  margin-bottom: 1em;
  position: relative;
}

.LazyPicture.LazyPicture-no-bottom-margin {
  margin-bottom: 0;
}

.LazyPicture-hidden {
  position: absolute;
  left: 10000px;
}

@keyframes breathe {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.98);
  }
}

.LazyPicture-swirly-animation {
  animation: breathe 10s ease-in-out infinite;
  transform-origin: center;
}

.LazyPicture img {
  width: 100%;
  max-width: 256px;
  max-height: 256px;
}

.LazyPicture img.LazyPicture-small {
  max-width: 64px;
  max-height: 64px;
  min-width: 64px;
  min-height: 64px;
}

.LazyPicture img.LazyPicture-new-game-cover {
  min-width: 256px;
  min-height: 256px;
}
