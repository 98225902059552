html {
  box-sizing: border-box;
  background-color: #282c34;
  height: 100%;
  height: 100dvh;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;

}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  height: 100dvh;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #c1c1c1;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div,
span {
  margin: 0;
  padding: 0;
}

main,
div,
textarea,
pre {
  display: flex;
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 1em;
}

h2 button {
  font-size: .8em;
}

p {
  margin-top: .4em;
  margin-bottom: .4em;
}


/* Basic button styling */
button {
  background-color: lightgreen;
  font-size: 16px;
  padding: 6px 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  transition: all 0.3s ease;
  outline: none;
}

/* Button hover effect */
button:hover {
  background-color: #96f076;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Button active (pressed) effect */
button:active {
  background-color: #3e8e41;
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.warning-button {
  background-color: #f0ad4e;
  color: 4d4d4d;
  border: none;
}

.warning-button:hover {
  background-color: #f5c283;
}

.warning-button:active {
  background-color: #c97c31;
}

.cancel-button {
  background-color: #d9534f;
  color: white;
}

.cancel-button:hover {
  background-color: #e27a78;
}

.cancel-button:active {
  background-color: #b52b27;
}
