.ScrollToBottom {
    position: absolute;
    z-index: 40;
    right: 2em;
    bottom: .5em;
    font-size: 2em;
    cursor: pointer;
    color: #acea9a
}

.ScrollToBottom-hidden {
    display: none;
}
